export default class Weather {
    weatherId: string
    constructor(url: string, weatherId: string) {
        this.weatherId = weatherId;

        this.fetchWeatherData(url);
    }

    async fetchWeatherData(source: string): Promise<void> {
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                this.updateWeatherData(data);
            }
        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    }


    updateWeatherData(data: any) {
        const tempText = Math.round(data.main?.temp) + " °C";
        const tempTextTop = Math.round(data.main?.temp) + "  Degrees";
        const weatherText = data.weather[0]?.main;
        const weatherIconUrl = `http://openweathermap.org/img/w/${data.weather[0]?.icon}.png`;

        const weatherContainer = document.getElementById(this.weatherId);
        const temp = document.createElement("p");
        temp.textContent = tempText;
        temp.classList.add('heading');
        temp.classList.add('mid-weather');
        weatherContainer.appendChild(temp);
        const tempTop = document.createElement("p");
        tempTop.textContent = tempTextTop;
        tempTop.classList.add('heading');
        tempTop.classList.add('top-weather');
        weatherContainer.appendChild(tempTop);
        const weather = document.createElement("p");
        weather.classList.add('weather-type');
        weather.textContent = weatherText;
        weatherContainer.appendChild(weather);
        const weatherIcon = document.createElement("img");
        weatherIcon.src = weatherIconUrl;
        weatherIcon.classList.add("weather-icon");
        weatherContainer.appendChild(weatherIcon);
    }
}
