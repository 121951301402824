export default class ShowFilter {
    private productContainers: NodeListOf<HTMLDivElement> | null;
    private filteredProducts: HTMLElement[] | null;
    private totalPages: number | null;
    private itemsPerPage: number | null;
    private currentPage: number;
    private listContainer: HTMLElement | null;

    constructor(id: string, amount: string) {
        this.listContainer = document.getElementById(id);
        if (this.listContainer === null) {
            return;
        }

        this.itemsPerPage = parseInt(amount);
        this.productContainers = this.listContainer.querySelectorAll('.list-container');
        this.filteredProducts = Array.from(this.productContainers)
        this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
        this.currentPage = 1;

        this.showPage(this.currentPage);
        this.createPaginationControls(this.listContainer);
    }

    public showPage(pageNumber: number): void {
        const filteredProducts: HTMLDivElement[] = Array.from(this.productContainers)
        const startIndex: number = (pageNumber - 1) * this.itemsPerPage;
        const endIndex: number = Math.min(startIndex + this.itemsPerPage, filteredProducts.length);

        this.filteredProducts = filteredProducts;
        this.filteredProducts.forEach((container: HTMLDivElement, index: number) => {
            if (index >= startIndex && index < endIndex) {
                container.style.display = 'flex';
            } else {
                container.style.display = 'none';
            }
        });

        // Scroll to the top 
        if (this.listContainer !== null) {
            const offset = -300
            const elementPosition = this.listContainer.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    public createPaginationControls(listContainer: HTMLElement): void {
        let paginationContainer: HTMLElement | null = listContainer.querySelector('.pagination');
        let productsContainer: HTMLElement | null = listContainer.querySelector('.list-products');

        if (paginationContainer) {
            paginationContainer.remove();
        }

        paginationContainer = document.createElement('div');
        paginationContainer.classList.add('pagination');

        const prevButton: HTMLButtonElement = document.createElement('button');
        prevButton.textContent = 'Previous';
        prevButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
        prevButton.addEventListener('click', () => {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.showPage(this.currentPage);
                this.updatePaginationButtons();
            }
        });
        paginationContainer.appendChild(prevButton);

        const nButtons: HTMLElement = document.createElement('div');
        for (let i = 1; i <= this.totalPages!; i++) {
            const pageButton: HTMLButtonElement = document.createElement('button');
            pageButton.textContent = String(i);
            pageButton.addEventListener('click', () => {
                this.currentPage = i;
                this.showPage(this.currentPage);
                this.updatePaginationButtons();
            });
            nButtons.appendChild(pageButton);
        }
        paginationContainer.appendChild(nButtons);

        const nextButton: HTMLButtonElement = document.createElement('button');
        nextButton.textContent = 'Next';
        nextButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
        nextButton.addEventListener('click', () => {
            if (this.currentPage < this.totalPages!) {
                this.currentPage++;
                this.showPage(this.currentPage);
                this.updatePaginationButtons();
            }
        });
        paginationContainer.appendChild(nextButton);

        productsContainer!.appendChild(paginationContainer);
        this.updatePaginationButtons();
    }

    public updatePaginationButtons(): void {
        const paginationButtons: NodeListOf<HTMLButtonElement> = this.listContainer!.querySelectorAll('.pagination button');
        paginationButtons.forEach((button: HTMLButtonElement) => {
            button.classList.add('c1-text');
            const pageNumber: number = parseInt(button.textContent!);
            if (pageNumber === this.currentPage) {
                button.classList.add('active');
            } else {
                button.classList.remove('active');
            }
        });
    }
}
