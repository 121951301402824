
/**
 * @summary Converts a relative URL to an absolute site URL
 * @param relative Relative URL (~/Umbraco/API/{controller}/{endpoint})
 */
export function RouteAPI(relative: string) {

    // Remove tilde if provided
    if (relative.charAt(0) === '~') {
        relative = relative.substring(1);
    }

    // Add forward slash if not provided
    if (relative.charAt(0) !== '/') {
        relative = `/${relative}`;
    }

    // "relative" should now start with a forward slash
    let url: string = '';

    // Route with port if running in localhost
    if (window.location.hostname === 'localhost') {
        url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${relative}`;
    }
    else {
        url = `${window.location.protocol}//${window.location.hostname}${relative}`;
    }

    return url;
}