import CreateEvents from '../../ts/components/create-events';

export default class EventTagFilter {
    private listContainer: HTMLElement | null;
    private eventsComponent: HTMLElement | null;
    private maxItems: number;
    private backgroundLabel: string | null;
    private categoryMenu: string | null;
    private selectedValue: string | null;
    private sortValue: string | null;

    constructor(sortFilterOption: string, element: HTMLElement, categoryMenu: string, componentId: string, listingId: string, maxItems: number, backgroundLabel: string) {
        this.categoryMenu = categoryMenu;
        this.maxItems = maxItems;
        this.backgroundLabel = backgroundLabel;
        this.listContainer = document.getElementById(listingId);
        this.eventsComponent = document.getElementById(componentId);
        this.selectedValue = element.getAttribute("data-value");
        document.getElementById(sortFilterOption).innerText = this.selectedValue;
        const sort: HTMLElement = this.listContainer.querySelector(".sort-selected");
        if (sort != null) {
            this.sortValue = this.listContainer.querySelector(".sort-selected").innerHTML;
        }
        const search: HTMLInputElement = this.listContainer.querySelector(".search-input") as HTMLInputElement;
        let searchQuery: string = "";
        if (search != null) {
            const searchQueryEl: HTMLInputElement = this.listContainer.querySelector(".search-input") as HTMLInputElement;
            searchQuery = searchQueryEl.value;
        }
        const first = maxItems;

        const dropdownItems: NodeListOf<HTMLElement> = this.listContainer.querySelectorAll(".dropdown-item");
        dropdownItems.forEach(item => {
            item.querySelector("a")!.setAttribute("aria-selected", "false");
        });

        if (element) {
            document.getElementById(sortFilterOption)!.innerText = this.selectedValue;
            element.querySelector("a")!.setAttribute("aria-selected", "true");
        }

        this.fetchEventData(first, searchQuery, this.selectedValue);
    }

    async fetchEventData(first: number, searchQuery: string, selectedValue: string): Promise<void> {
        document.getElementById(this.categoryMenu)!.classList.remove("show");
        const source: string = `/api/events/search?first=${first}&searchTerm=${encodeURIComponent(searchQuery)}&sort=${this.sortValue}&tags=${selectedValue}`;
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                new CreateEvents(data, this.eventsComponent.id, this.maxItems, this.backgroundLabel, "False", null);
            }
        } catch (error) {
            console.error('Error fetching event data:', error);
        }
    }
}
