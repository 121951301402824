import * as $ from "jquery";
import "slick-carousel";
import { CartLineUpdateInputInterface } from "../interfaces/Shopify/CartLineUpdateInputInterface";
import { AttributeInterface } from "../interfaces/Shopify/AttributeInterface";
import { cartAfterUpdate, cartShow, cartToggle, ShopEvent } from "./Shop-Events";
import { AddToShopifyCart } from "./Shopify";

export default class Product {
    private product: HTMLElement;
    private variantType: string | null;
    private variantId: string | null;
    private timeId: string | null;
    private startDateTime: Date;
    private endDateTime: Date;

    constructor(product: HTMLElement, variantId: string, variantType: string, timeId: string, startDateTime: Date, endDateTime: Date) {
        this.product = product;
        this.variantId = variantId;
        this.variantType = variantType;
        this.timeId = timeId;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.initAddToCart();
        this.initImagesSlider();

        let cartBtn: HTMLButtonElement = this.product.querySelector(".go-to-cart");
        cartBtn.addEventListener("click", () => {
            ShopEvent.emit(cartToggle);
        });
    };

    private initImagesSlider(): void {
        let images: HTMLElement = this.product.querySelector(".product-images");

        $(images).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            centerMode: true,
            asNavFor: '.product-images-nav'
        });

        let imagesNav: HTMLElement = this.product.querySelector(".product-images-nav");

        $(imagesNav).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.product-images',
            dots: false,
            arrows: false,
            infinite: false,
            focusOnSelect: true
        });
    };

    private initAddToCart(): void {
        let fullId: string = "gid://shopify/" + this.variantType + "/" + this.variantId;

        let wrapper: HTMLElement = this.product.querySelector(".add-to-cart");
        let disabled: boolean = wrapper.classList.contains("disabled");
        let addBtn: HTMLButtonElement = this.product.querySelector(".button.add");

        if (!disabled) {
            let attributes: AttributeInterface[] = [];
            if (this.timeId != null && this.timeId != undefined) {
                const when: AttributeInterface = {
                    key: "When",
                    value: `${this.formatDateTime(this.startDateTime)} to ${this.formatDateTime(this.endDateTime)}`
                };

                const bookingType: AttributeInterface = {
                    key: "BookingType",
                    value: "REGULAR"
                };

                const timeslot: AttributeInterface = {
                    key: "_Timeslot",
                    value: this.timeId
                };

                attributes.push(when, bookingType, timeslot);
            }

            const newAddBtn = addBtn.cloneNode(true) as HTMLButtonElement;
            addBtn.parentNode?.replaceChild(newAddBtn, addBtn);

            newAddBtn.addEventListener("click", () => {
                this.showBtnLoading(newAddBtn);
                const line: CartLineUpdateInputInterface = {
                    merchandiseId: fullId,
                    quantity: 1,
                    attributes: attributes
                };

                let lines: CartLineUpdateInputInterface[] = [line];
                AddToShopifyCart(lines);
                ShopEvent.on(cartAfterUpdate, () => {
                    this.hideBtnLoading(newAddBtn);
                    ShopEvent.emit(cartShow);
                });
            });
        }
    }

    private showBtnLoading(btn: HTMLButtonElement): void {
        const loadingSpan: HTMLSpanElement = btn.querySelector(".sm");

        if (loadingSpan == undefined) {
            const newLoading: HTMLSpanElement = document.createElement("span");
            newLoading.classList.add("sm");
            newLoading.classList.add("sm-loading");
            newLoading.classList.add("sm-is-spinning");
            btn.appendChild(newLoading)
        }
    };

    private hideBtnLoading(btn: HTMLButtonElement): void {
        const loadingSpan: HTMLSpanElement = btn.querySelector(".sm");

        if (loadingSpan != undefined) {
            btn.removeChild(loadingSpan);
        }
    };

    private formatDateTime(eventTime: Date): string {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let dateString: string;

        const date: Date = new Date(eventTime);
        const month: string = monthNames[date.getMonth()];
        const day: number = date.getDate();
        const year: number = date.getFullYear();
        const minutes: number = date.getMinutes();
        let zz: string = "am";
        let hour: number = date.getHours();

        if (hour > 12) {
            hour = hour - 12;
        }

        if (hour >= 12) {
            zz = "pm";
        }

        dateString = `${month} ${day.toString()}, ${year} at ${this.formatDateTimeNumber(hour)}:${this.formatDateTimeNumber(minutes)}${zz} AWST(+0800)`

        return dateString;
    }

    private formatDateTimeNumber(number: number): string {

        if (number < 10) {
            return "0" + number.toString();
        }
        else {
            return number.toString()
        }
    }
}