import CreateEvents from '../../ts/components/create-events';
export default class SearchEvents {
    private listContainer: HTMLElement | null;
    private eventsComponent: HTMLElement | null;
    private maxItems: number | null;
    private backgroundLabel: string | null;

    constructor(listingId: string, componentId: string, maxItems: number, backgroundLabel: string) {
        this.backgroundLabel = backgroundLabel;
        this.maxItems = maxItems;
        this.listContainer = document.getElementById(listingId);
        this.eventsComponent = document.getElementById(componentId);
        const searchBtn: HTMLButtonElement = this.listContainer.querySelector(".search-btn") as HTMLButtonElement;

        if (searchBtn) {
            searchBtn.addEventListener('click', () => {
                const searchQueryEl: HTMLInputElement = this.listContainer.querySelector(".search-input") as HTMLInputElement;
                const searchQuery: string = searchQueryEl.value;

                const first = maxItems;
                this.fetchEventData(first, searchQuery);
            });
        }
    }

    async fetchEventData(first: number, searchQuery: string): Promise<void> {
        const source: string = `/api/events/search?first=${first}&searchTerm=${encodeURIComponent(searchQuery)}`;
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                if (data.length != 0) {
                    new CreateEvents(data, this.eventsComponent.id, this.maxItems, this.backgroundLabel, "", null);
                } else {
                    this.noResults();
                }
            }
        } catch (error) {
            console.error('Error fetching event data:', error);
        }
    }

    private noResults(): void {
        const listEventsDiv = document.querySelector('.list-events');

        if (listEventsDiv) {
            listEventsDiv.innerHTML = '';

            const noResultsText = document.createElement('p');
            noResultsText.textContent = 'No search results found';
            noResultsText.style.textAlign = "center";
            listEventsDiv.appendChild(noResultsText);
        }
    }
}
