import Offcanvas from 'bootstrap/js/dist/offcanvas.js';
import { getCookie, removeCookie } from "typescript-cookie";
import { BaseCartLineInterface } from "../interfaces/Shopify/BaseCartLineInterface";
import { CartInterface } from "../interfaces/Shopify/CartInterface";
import { CartLineUpdateInputInterface } from "../interfaces/Shopify/CartLineUpdateInputInterface";
import { cartInit, cartUpdate, cartToggle, ShopEvent, cartAfterUpdate, cartShow, cartHide } from "./Shop-Events";
import { GetShopifyCart, RemoveFromShopifyCart, UpdateShopifyCart } from "./Shopify";
import { isEmptyOrUndefined } from "./Util";

export default class Cart {
    private cartBtn: HTMLButtonElement;
    public cartEl: HTMLElement;
    private cartPanel: Offcanvas;
    private cartId: string;

    constructor() {
        this.cartBtn = document.getElementById("cartBtn") as HTMLButtonElement;
        this.cartEl = document.getElementById("cart");
        this.cartId = getCookie("cart");
        let closeBtn: HTMLButtonElement = this.cartEl.querySelector(".btn-close");

        ShopEvent.on(cartInit, () => {
            if (!isEmptyOrUndefined(this.cartId)) {
                GetShopifyCart<CartInterface>(this.cartId)
                    .then((cart: CartInterface) => {
                        if (cart.id != null && cart.id != undefined) {
                            UpdateCart(cart);
                            UpdateCartCount(cart.totalQuantity);
                        }
                        else {
                            RemoveCartCookies();
                        }
                    });
            }
            else {
                RemoveCartCookies();
            }
        });

        ShopEvent.on(cartUpdate, (cart: CartInterface) => {
            ShowLoadingOverlay();
            UpdateCart(cart);
            UpdateCartCount(cart.totalQuantity);
        });

        ShopEvent.on(cartAfterUpdate, () => {
            HideLoadingOverlay();
        });

        ShopEvent.on(cartToggle, () => {
            this.cartPanel.toggle();
        });

        ShopEvent.on(cartShow, () => {
            this.cartPanel.show();
        });

        ShopEvent.on(cartHide, () => {
            this.cartPanel.hide();
        });

        closeBtn.addEventListener("click", () => {
            this.cartPanel.toggle();
        });

        this.initCart();
    }

    private initCart(): void {

        this.cartPanel = new Offcanvas(this.cartEl);

        if (this.cartBtn != null) {
            this.cartBtn.addEventListener("click", () => {
                this.cartPanel.toggle();
            });

            ShopEvent.emit(cartInit);
        }

    }
};

export function UpdateCart(cart: CartInterface): void {
    const cartEl = document.getElementById("cart");
    const checkoutBtn: HTMLAnchorElement = cartEl.querySelector(".btn-checkout");
    const cartLines: HTMLDivElement = cartEl.querySelector(".cart-lines");
    const cartTotal: HTMLDivElement = cartEl.querySelector(".cart-total");

    // Cart line template element
    const lineTemplate: HTMLDivElement = cartEl.querySelector(".template .cart-line.template");
    cartLines.innerHTML = "";

    if (cart != null && cart != undefined) {
        if (cart.lines != null && cart.lines != undefined) {
            cart.lines.forEach((line: BaseCartLineInterface) => {
                const lineEl: HTMLDivElement = lineTemplate.cloneNode(true) as HTMLDivElement;
                const lineImage: HTMLImageElement = lineEl.querySelector(".line-image img");
                const lineTitle: HTMLDivElement = lineEl.querySelector(".line-title");
                const lineQuantity: HTMLInputElement = lineEl.querySelector(".line-quantity");
                const lineUpdateBtn: HTMLButtonElement = lineEl.querySelector(".btn-update");
                const lineRemoveBtn: HTMLButtonElement = lineEl.querySelector(".btn-remove");
                const lineTotal: HTMLSpanElement = lineEl.querySelector(".line-total");

                lineImage.src = line.merchandise.image.url + "&height=190&width=190";

                let title = line.merchandise.product.title;
                if (line.merchandise.title != null && line.merchandise.title != undefined && line.merchandise.title !== "") {
                    title = `${line.merchandise.product.title} (${line.merchandise.title})`;
                }

                lineTitle.innerText = title;
                lineQuantity.value = line.quantity.toString();

                const formattedAmount: string = (Math.round(line.cost.totalAmount.amount * 100) / 100).toFixed(2);
                lineTotal.innerText = "$" + formattedAmount;

                lineEl.classList.remove("template");
                cartLines.appendChild(lineEl);

                lineRemoveBtn.addEventListener("click", () => {
                    ShowLoadingOverlay();
                    RemoveFromShopifyCart(line.id);
                });

                lineUpdateBtn.addEventListener("click", () => {
                    let newQuantity: number = parseInt(lineQuantity.value);

                    if (lineQuantity.value == "" || lineQuantity.value == undefined) {
                        newQuantity = 0;
                    }
                    const updatedLine: CartLineUpdateInputInterface = {
                        id: line.id,
                        quantity: newQuantity
                    }

                    const updatedLines: CartLineUpdateInputInterface[] = [updatedLine];

                    ShowLoadingOverlay();
                    UpdateShopifyCart(updatedLines);
                });
            });
        }

        if (cart.cost != null && cart.cost != undefined) {
            // Set cart total
            const formattedAmount: string = (Math.round(cart.cost.totalAmount.amount * 100) / 100).toFixed(2);
            cartTotal.innerText = "$" + formattedAmount;
        }
    }

    // Set checkout link
    checkoutBtn.addEventListener("click", () => {
        window.open(cart.checkoutUrl, '_blank');
    });

    if (cart.lines == null || cart.lines == undefined || cart.lines.length == 0) {
        cartLines.innerHTML = "<div class='empty'>Your cart is empty</div>";
        checkoutBtn.setAttribute("disabled", "");
    }
    else {
        checkoutBtn.removeAttribute("disabled");
    }

    ShopEvent.emit(cartAfterUpdate);
};

export function UpdateCartCount(count: number): void {
    if (count != null && count != undefined) {
        const cartCount: HTMLSpanElement = document.querySelector(".shopping-cart__count");
        cartCount.setAttribute("data-cartcount", count.toString());
        cartCount.innerText = "(" + count.toString() + ")";
    }
};

export function ShowLoadingOverlay(): void {
    const cartEl = document.getElementById("cart");
    const overlay = cartEl.querySelector(".loading");
    overlay.classList.add("show");
};

export function HideLoadingOverlay(): void {
    const cartEl = document.getElementById("cart");
    const overlay = cartEl.querySelector(".loading");
    if (overlay.classList.contains("show")) {
        overlay.classList.remove("show");
    }
};

export function RemoveCartCookies(): void {
    removeCookie("cart", {path: "/shop/event"});
    removeCookie("carturl", { path: "/shop/event" });
}