import { RouteAPI } from '../helpers/url-helpers';
import 'slick-carousel';

interface InstagramMedia {
    caption: string; 
    id: string; 
    media_type: string;
    media_url: string;
    permalink: string;
    timestamp: string;
    username: string;
    thumbnail_url: string;
}

interface InstagramResponse {
    data: InstagramMedia[];
}

export default class Instagram {
    PhotoList: HTMLDivElement;
    NumberOfItems: number;
    SlickAttributes: string;

    /**
     * Initialise image element reference and call Instagram API
     * @param id ID of image element
     */
    constructor(id: string, numberOfItems: string) {
        this.PhotoList = document.getElementById(id) as HTMLDivElement;
        this.NumberOfItems = parseInt(numberOfItems, 10);
        this.SlickAttributes = this.PhotoList.getAttribute('data-slick');

        // Call API which retrieves media from Instagram
        const url = RouteAPI('~/api/instagram/post/allmedia');
        const request = new XMLHttpRequest();
        request.open('POST', url);
        request.send();
        request.onload = () => {
            if (request.status === 200) {
                const response: InstagramResponse = JSON.parse(request.response);
                // Ensure media items are newest - oldest
                response.data.sort((a: InstagramMedia, b: InstagramMedia): number => {
                    return (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any);
                });

                // Select media that is either an image or video
                const result: InstagramMedia[] = response.data.filter((a: InstagramMedia) => {
                    return a.media_type === 'IMAGE' || a.media_type === 'VIDEO';
                });
                this.DisplayImages(result);
            }
            else {
                console.error(request.response);
            }
        }
    }

    /**
     * Add the images to the page as DIV elements
     * @param media List of images from the API
     */
    DisplayImages(media: InstagramMedia[]) {

        // If there aren't enough images, pad the array with copies of the first image
        if (media.length < this.NumberOfItems) {

            const diff: number = this.NumberOfItems - media.length;

            console.error(`Not enough instagram images, padding with ${diff} images`);

            for (let i: number = 0; i < diff; i++) {

                media.push(media[0]);
            }
        }

        // Create image elements and add to page
        media.slice(0, this.NumberOfItems).forEach((item: InstagramMedia, index: number) => {
            const mediaAnchor: HTMLAnchorElement = document.createElement('a');
            mediaAnchor.href = item.permalink;
            mediaAnchor.classList.add('instagram-grid');
            mediaAnchor.classList.add(`grid_${index + 1}`);

            const mediaElement: HTMLDivElement = document.createElement('div');
            mediaElement.classList.add('instagram-photo');

            if (item.media_type === 'IMAGE') {
                mediaElement.style.backgroundImage = `url(${item.media_url})`;
            }
            else if (item.media_type === 'VIDEO') {
                // Retrieve the video thumbnail URL
                const thumbnailUrl = item.thumbnail_url || item.media_url;
                mediaElement.style.backgroundImage = `url(${thumbnailUrl})`;
            }

            mediaAnchor.append(mediaElement);
            this.PhotoList.append(mediaAnchor);
        });

        $(this.PhotoList).slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerMode: true,
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });
    }
}