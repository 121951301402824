import * as $ from 'jquery';
import 'slick-carousel';
import ShowFilter from "../components/show-filter";
export default class ViewFilter {
    private listContainer: HTMLElement | null;
    private id: string | null;
    constructor(id: string, view: string) {
        this.id = id;
        this.listContainer = document.getElementById(id);
        if (view == 'grid') {
            this.initGrid();
        } else {
            this.initCarousel();
        }
    }

    private initGrid(): void {
        let products: HTMLElement = this.listContainer.querySelector(".products");
        if (products.classList.contains('slick-initialized')) {
            $(products).slick('unslick');
            new ShowFilter(this.id, '12');
            const pagination: HTMLElement = this.listContainer.querySelector(".pagination");
            if (pagination != null) {
                pagination.classList.remove('d-none');
            }
        } 
    }

    private initCarousel(): void {
        let products: HTMLElement = this.listContainer.querySelector(".products");
        const pagination: HTMLElement = this.listContainer.querySelector(".pagination");
        if (pagination != null) {
            pagination.classList.add('d-none');
        }
        if (!products.classList.contains('slick-initialized')) {
            $(products).slick({
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                pauseOnHover: true,
                variableWidth: true,
                arrows: false,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }
}