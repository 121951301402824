import { Event, EventId, ExperienceImage, Timeslot } from '../interfaces/Experiences/EventsInterface';
export default class CreateEvents {
    private listContainer: HTMLElement | null;
    private maxItems: number | null;
    private backgroundLabel: string | null;
    private eventsPerPage: number;
    private currentPage: number;
    private totalPages: number;
    private totalEvents: number = 0;
    private eventDetailsUrl: string | null;
    private sort: string | null;

    constructor(data: EventId[] | any[], componentId: string, maxItems: number, backgroundLabel: string, sort: string) {
        this.maxItems = maxItems;
        this.sort = sort;
        this.backgroundLabel = backgroundLabel;
        this.eventsPerPage = 5;
        this.currentPage = 1;
        this.listContainer = document.getElementById(componentId);
        if (this.listContainer) {
            this.initialize(data);
        }
    }

    private async initialize(data: EventId[]): Promise<void> {
        try {
            if (data.length === 0) {
                data = await this.fetchEventData();
            }
            this.eventDetailsUrl = "/shop/event/";
            await this.loadEvents(data);

            this.totalPages = Math.ceil(this.totalEvents / this.eventsPerPage);
            this.showPage(this.currentPage);
            this.createPaginationControls();
        } catch (error) {
            console.error("Error loading events and events details URL:", error);
        }
    }

    async fetchEventData(): Promise<EventId[]> {
        const source: string = `/api/events/search?first=${this.maxItems}`;
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                return data;
            }

            return [];
        } catch (error) {
            console.error('Error fetching event data:', error);
            return [];
        }
    }

    private async loadEvents(data: EventId[]): Promise<void> {
        const eventHtmlPromises = data.map(eventId => this.createEventHtml(eventId));
        const eventHtmlArray = await Promise.all(eventHtmlPromises);

        if (this.listContainer) {
            this.listContainer.innerHTML = '';
            eventHtmlArray.forEach(eventHtml => {
                this.listContainer.insertAdjacentHTML('beforeend', eventHtml);
            });
        }
    }

    async createEventHtml(eventId: EventId): Promise<string> {
        const experienceId: string = eventId.id;
        const currentDateTime: string = new Date().toISOString();
        const experience: Event = await this.getEventById(experienceId, currentDateTime, this.maxItems);
        const variantPrice: number = experience.variants[0]?.price || 0;
        const eventTags: string = eventId.tags.join(',');

        if (experience) {
            const featuredImage: ExperienceImage = experience.images?.find((image: ExperienceImage) => image.featured);
            const isFree: string = experience.paymentType === "free" ? "Free" : "Paid";

            let eventsArray: { startDateTime: Date, html: string }[] = [];

            experience.experience.timeSlots.forEach((exp: Timeslot) => {
                this.totalEvents++;
                let ticketsAvailable = "Tickets Available";
                let availability = "available";
                if (exp.remaining <= 0) {
                    availability = "not-available";
                    ticketsAvailable = "Sold Out";
                }

                const eventDetailsLink: string = `${this.eventDetailsUrl}?id=${experienceId}&time=${exp.id}`;
                const startDateTime = new Date(exp.startsAt);
                const endDateTime = new Date(exp.endsAt);

                const formattedDate = startDateTime.toLocaleDateString('en-AU', { day: '2-digit', month: 'short' }).toUpperCase();
                const day = startDateTime.toLocaleDateString('en-AU', { day: '2-digit' });
                const month = startDateTime.toLocaleDateString('en-AU', { month: '2-digit' });
                const year = startDateTime.toLocaleDateString('en-AU', { year: 'numeric' }); 

                const formattedStartTime = startDateTime.toLocaleTimeString('en-AU', { hour: 'numeric', minute: '2-digit', hour12: true }).replace('am', 'AM').replace('pm', 'PM');
                const formattedEndTime = endDateTime.toLocaleTimeString('en-AU', { hour: 'numeric', minute: '2-digit', hour12: true }).replace('am', 'AM').replace('pm', 'PM');

                const eventHtml = `
                <div class="event-container list-container" data-tags="${eventTags}" data-title="${experience.title}" data-date="${formattedDate}" data-price="${variantPrice}">
                    <div class="event ${this.backgroundLabel + '-bg'} ${this.backgroundLabel + '-borders'}">
                        <div class="event-image">
                            ${featuredImage ? `<div class="event-featured-image"><img src="${featuredImage.url}" /></div>` : ''}
                        </div>
                        <div class="event-info">
                            <div class="event-detail-container">
                                <div class="event-title ${this.backgroundLabel + '-heading'}">${experience.name}</div>
                                <div class="event-date">${day}/${month}/${year}</div>
                                <div class="event-details ${this.backgroundLabel + '-text'}">${experience.description}</div>
                            </div>
                            <div class="event-time">${formattedStartTime} - ${formattedEndTime}</div>
                        </div>
                        <div class="event-ticket-info">
                            <div class="event-price ${this.backgroundLabel + '-text'}">${isFree}</div>
                            <div class="event-availability ${availability}">${ticketsAvailable}</div>
                        </div>
                        <div class="event-tickets">
                            <a href="${eventDetailsLink}" class="btn base-btn-bg base-btn-bg-solid base-btn-bg-hover-solid base-btn-text base-btn-borders">
                                <span></span>
                                <div class="text">SEE EVENT</div>
                            </a>
                        </div>
                    </div>
                    <img class="background-decoration" src="/media/cqijjzyz/bottom-decor-transparent-white-1x.png" />
                </div>`;

                eventsArray.push({ startDateTime, html: eventHtml });
            });

            if (this.sort == "Sort: Date") {
                eventsArray.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());
            }

            let sortedEventHtml = eventsArray.map(event => event.html).join('');

            return sortedEventHtml;
        }

        return '';
    }


    async getEventById(experienceId: string, currentDateTime: string, maxItems: number): Promise<Event> {
        const response: Response = await fetch(`/api/events/get/byId?experienceId=${experienceId}&currentDateTime=${currentDateTime}&maxItems=${maxItems}`);
        if (!response.ok) {
            throw new Error(`Error fetching event data: ${response.statusText}`);
        }
        return await response.json();
    }

    async getEventDetailsPageUrl(): Promise<string> {
        try {
            const response = await fetch(`/api/events/get/Url`);
            if (!response.ok) {
                throw new Error(`Error fetching event details page URL: ${response.statusText}`);
            }
            const data = await response.json();
            const eventPageDetailsUrl = data.eventPageDetailsUrl;
            return eventPageDetailsUrl;
        } catch (error) {
            console.error("Error getting event details page URL:", error);
            return '';
        }
    }


    private showPage(pageNumber: number): void {
        if (this.listContainer) {
            const eventContainers = this.listContainer.querySelectorAll<HTMLDivElement>('.list-container');
            const startIndex = (pageNumber - 1) * this.eventsPerPage;
            const endIndex = Math.min(startIndex + this.eventsPerPage, eventContainers.length);

            eventContainers.forEach((container, index) => {
                container.style.display = index >= startIndex && index < endIndex ? 'flex' : 'none';
            });
        }
    }

    private scrollToTop(): void {
        const offset = -300;
        const elementPosition = this.listContainer.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    private createPaginationControls(): void {
        if (this.listContainer) {
            let paginationContainer = this.listContainer.querySelector<HTMLElement>('.pagination');

            if (paginationContainer) {
                paginationContainer.remove();
            }

            paginationContainer = document.createElement('div');
            paginationContainer.classList.add('pagination');

            const prevButton = document.createElement('button');
            prevButton.textContent = 'Previous';
            prevButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
            prevButton.addEventListener('click', () => {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                    this.scrollToTop();
                }
            });
            paginationContainer.appendChild(prevButton);

            const nButtons = document.createElement('div');
            for (let i = 1; i <= this.totalPages; i++) {
                const pageButton = document.createElement('button');
                pageButton.textContent = String(i);
                pageButton.addEventListener('click', () => {
                    this.currentPage = i;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                    this.scrollToTop();
                });
                nButtons.appendChild(pageButton);
            }
            paginationContainer.appendChild(nButtons);

            const nextButton = document.createElement('button');
            nextButton.textContent = 'Next';
            nextButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
            nextButton.addEventListener('click', () => {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                    this.scrollToTop();
                }
            });
            paginationContainer.appendChild(nextButton);

            this.listContainer.appendChild(paginationContainer);
            this.updatePaginationButtons();
        }
    }

    private updatePaginationButtons(): void {
        if (this.listContainer) {
            const paginationButtons = this.listContainer.querySelectorAll<HTMLButtonElement>('.pagination button');
            paginationButtons.forEach(button => {
                button.classList.add(this.backgroundLabel + '-text');
                const pageNumber = parseInt(button.textContent!);
                if (pageNumber === this.currentPage) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }
            });
        }
    }
}
