import { EventEmitter } from 'events';
import { CartInterface } from '../interfaces/Shopify/CartInterface';

class ShopEvents extends EventEmitter { }
export const ShopEvent = new ShopEvents();

export interface InitEventData {
    cart: CartInterface
}

export const cartInit: string = 'cart:init';
export const cartUpdate: string = 'cart:update';
export const cartAfterUpdate: string = 'cart:afterupdate';
export const cartToggle: string = 'cart:toggle';
export const cartShow: string = 'cart:show';
export const cartHide: string = 'cart:hide';
export const cartLineAdd: string = 'cartLine:add';
export const cartLineUpdate: string = 'cartLine:update';
export const cartLineRemove: string = 'cartLine:remove';