import * as $ from 'jquery';
import 'slick-carousel';

export default class ProductList {
    private listContainer: HTMLElement | null;
    constructor(id: string) {
        this.listContainer = document.getElementById(id);
        this.initCarousel();
    }

    private initCarousel(): void {
        let products: HTMLElement = this.listContainer

        $(products).slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}