import CreateEvents from '../../ts/components/create-events';

export default class SortEventFilter {
    private listContainer: HTMLElement | null;
    private eventsComponent: HTMLElement | null;
    private maxItems: number;
    private backgroundLabel: string | null;
    private sortMenu: string | null;
    private selectedValue: string | null;

    constructor(sortFilterOption: string, element: HTMLElement, sortMenu: string, componentId: string, listingId: string, maxItems: number, backgroundLabel: string) {
        this.sortMenu = sortMenu;
        this.maxItems = maxItems;
        this.backgroundLabel = backgroundLabel;
        this.listContainer = document.getElementById(listingId);
        this.eventsComponent = document.getElementById(componentId);

        this.selectedValue = element != null ? element.getAttribute("data-value")! : document.getElementById(sortFilterOption)!.innerText;
        const searchQueryEl: HTMLInputElement = this.listContainer.querySelector(".search-input") as HTMLInputElement;
        const searchQuery: string = searchQueryEl.value;
        const first = maxItems;

        const dropdownItems: NodeListOf<HTMLElement> = this.listContainer.querySelectorAll(".dropdown-item");
        dropdownItems.forEach(item => {
            item.querySelector("a")!.setAttribute("aria-selected", "false");
        });

        if (element) {
            document.getElementById(sortFilterOption)!.innerText = this.selectedValue;
            element.querySelector("a")!.setAttribute("aria-selected", "true");
        }

        switch (this.selectedValue) {
            case "Price (low - high)":
                this.selectedValue = "1-2"
                break;
            case "Price (high - low)":
                this.selectedValue = "2-1";
                break;
            default:
        }

        this.fetchEventData(first, searchQuery, this.selectedValue);
    }

    async fetchEventData(first: number, searchQuery: string, selectedValue: string): Promise<void> {
        const source: string = `/api/events/search?first=${first}&searchTerm=${encodeURIComponent(searchQuery)}&sort=${selectedValue}`;
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                document.getElementById(this.sortMenu)!.classList.remove("show");
                new CreateEvents(data, this.eventsComponent.id, this.maxItems, this.backgroundLabel, this.selectedValue, null);
            }
        } catch (error) {
            console.error('Error fetching event data:', error);
        }
    }
}
