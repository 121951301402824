export default class PriceFilter {
    private listingContainer: HTMLElement | null;

    constructor(id: string, listingId: string) {
        this.listingContainer = document.getElementById(listingId);
        const rangeInput = document.getElementById(id) as HTMLInputElement;
        this.updateRangeStyles(rangeInput);
            
        rangeInput.addEventListener('input', (event: Event) => {
            const currentValue = (event.target as HTMLInputElement).value;
            this.showChange(currentValue);
            this.updateRangeStyles(rangeInput);
        });
    }

    private updateRangeStyles(input: HTMLInputElement): void {
        const currentValue = input.value;
        const max = input.max;
        const percentage = (parseInt(currentValue) / parseInt(max)) * 100;
        input.style.background = `linear-gradient(to right, rgb(0, 174, 239) ${percentage}%, #fff ${percentage}% 100%)`;
    }

    private showChange(amount: string): void {
        const priceFilterValue = parseFloat(amount);

        const productContainers: NodeListOf<HTMLElement> = this.listingContainer.querySelectorAll('.product-container');

        productContainers.forEach((container: HTMLElement) => {
            const productPriceElement: HTMLElement = container.querySelector('.product-price .price-lg');
            if (productPriceElement) {
                const productPrice: number = parseFloat(productPriceElement.textContent!);
                if (productPrice > priceFilterValue) {
                    container.style.display = 'none';
                    container.classList.add('price-filtered');
                } else {
                    if (!container.classList.contains('tag-filtered')) {
                        container.style.display = 'flex';
                    }
                    container.classList.remove('price-filtered');
                }
            }
        });
    }
}
