import CreateProducts from '../../ts/components/create-products';

export default class SortProductFilter {
    private listContainer: HTMLElement | null;
    private eventsComponent: HTMLElement | null;
    private backgroundLabel: string | null;
    private sortMenu: string | null;
    private selectedValue: string | null;
    private productsPerPage: number;
    private tagValue: string | null;

    constructor(sortFilterOption: string, element: HTMLElement, sortMenu: string, componentId: string, listingId: string, backgroundLabel: string, itemsPerPage: number) {
        this.sortMenu = sortMenu;
        this.productsPerPage = itemsPerPage;
        this.backgroundLabel = backgroundLabel;
        this.listContainer = document.getElementById(listingId);
        this.eventsComponent = document.getElementById(componentId);

        this.selectedValue = element != null ? element.getAttribute("data-value")! : document.getElementById(sortFilterOption)!.innerText;
        this.tagValue = this.listContainer.querySelector(".tags-selected").innerHTML;
        if (this.tagValue == "-- Select a Category --") {
            let selectedTags = Array.from(this.listContainer.querySelectorAll('.tag-filter input[type="checkbox"]:checked'))
                .map((checkedCheckbox: HTMLInputElement) => checkedCheckbox.nextElementSibling.nextElementSibling.textContent)
                .join(',');
            this.tagValue = selectedTags;
        }

        const dropdownItems: NodeListOf<HTMLElement> = this.listContainer.querySelectorAll(".dropdown-item");
        dropdownItems.forEach(item => {
            item.querySelector("a")!.setAttribute("aria-selected", "false");
        });

        if (element) {
            document.getElementById(sortFilterOption)!.innerText = this.selectedValue;
            element.querySelector("a")!.setAttribute("aria-selected", "true");
        }

        switch (this.selectedValue) {
            case "Price (low - high)":
                this.selectedValue = "1-2"
                break;
            case "Price (high - low)":
                this.selectedValue = "2-1";
                break;
            default:
        }

        const searchField = document.getElementById("searchProductField") as HTMLInputElement;
        const searchValue = searchField ? searchField.value.trim() : ''; 

        this.fetchProductData(this.selectedValue, searchValue);
    }

    async fetchProductData(selectedValue: string, searchValue: string): Promise<void> {
        const source: string = `/api/products/filter?tag=${this.tagValue}&sort=${selectedValue}&search=${encodeURIComponent(searchValue)}`;

        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                document.getElementById(this.sortMenu)!.classList.remove("show");
                new CreateProducts(data, this.eventsComponent.id, this.backgroundLabel, this.productsPerPage, "False", "True");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }
}
