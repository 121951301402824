import ProductTagFilter from '../../ts/components/product-tag-filter';

export default class Tags {
    private componentId: string | null;
    private listingId: string | null;
    private backgroundLabel: string | null;

    constructor(sideComponentId: string, currentFilter: string, componentId: string, listingId: string, backgroundLabel: string) {
        this.componentId = componentId;
        this.listingId = listingId;
        this.backgroundLabel = backgroundLabel;

        const sideComponentEl: HTMLElement = document.getElementById(sideComponentId);
        const checkboxes: NodeListOf<HTMLInputElement> = sideComponentEl.querySelectorAll('.tag-filter input[type="checkbox"]');
        const currentFilterContainer: HTMLElement = document.getElementById(currentFilter);
        const currentFilterEl: HTMLElement = currentFilterContainer.querySelector('.tags');

        checkboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.addEventListener('change', () => {
                const selectedTags = Array.from(sideComponentEl.querySelectorAll('.tag-filter input[type="checkbox"]:checked'))
                    .map((checkedCheckbox: HTMLInputElement) => checkedCheckbox.nextElementSibling.nextElementSibling.textContent)
                    .join(',');

                this.updateFilterDisplay(selectedTags, currentFilterEl);
                new ProductTagFilter(null, null, null, this.componentId, this.listingId, this.backgroundLabel, selectedTags);
            });
        });
    }

    private updateFilterDisplay(selectedTags: string, currentFilterEl: HTMLElement | null): void {
        if (!currentFilterEl) return;
        currentFilterEl.innerHTML = '';

        const tagsArray = selectedTags.split(',');

        tagsArray.forEach(tagName => {
            const tagDiv = document.createElement('div');
            tagDiv.classList.add('tag');
            tagDiv.textContent = tagName;
            currentFilterEl.appendChild(tagDiv);
        });
    }
}
