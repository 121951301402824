import { ProductInterface } from '../interfaces/Shopify/ProductInterface';
import { MoneyV2Interface } from '../interfaces/Shopify/MoneyV2Interface';
import ProductList from '../components/product-list';
import { ProductVariantInterface } from '../interfaces/Shopify/ProductVariantInterface';
import { CartLineUpdateInputInterface } from '../interfaces/Shopify/CartLineUpdateInputInterface';
import { AddToShopifyCart } from './Shopify';
import { cartAfterUpdate, cartShow, ShopEvent } from './Shop-Events';

export default class CreateProducts {
    private listContainer: HTMLElement | null;
    private backgroundLabel: string | null;
    private productsPerPage: number;
    private currentPage: number;
    private totalPages: number;
    private totalProducts: number = 0;
    private productDetailsUrl: string | null;
    private listLayout: string | null;
    private componentId: string | null;
    private filters: string | null;
    constructor(data: ProductInterface[], componentId: string, backgroundLabel: string, itemsPerPage: number, listLayout: string, search?: string) {
        this.listLayout = listLayout;
        this.componentId = componentId
        this.backgroundLabel = backgroundLabel;
        this.productsPerPage = itemsPerPage;
        this.currentPage = 1;
        this.filters = search
        this.listContainer = document.getElementById(componentId);

        if (this.listContainer) {
            this.initialize(data);
        }
    }

    private async initialize(data: ProductInterface[]): Promise<void> {
        try {
            if (data.length === 0 && this.filters == null) {
                data = await this.fetchProductData();
            }

            this.productDetailsUrl = "/shop/product/";
            await this.loadProducts(data);

            if (this.listLayout == "False") {
                this.totalPages = Math.ceil(this.totalProducts / this.productsPerPage);
                this.showPage(this.currentPage);
                this.createPaginationControls();
            } else {
                new ProductList(this.componentId)
            }

            if (data.length === 0) {
                this.listContainer.innerHTML = 'No Products Found, Please adjust your search';
                this.listContainer.style.fontWeight = "600";
                this.listContainer.style.color = "red";
            }
        } catch (error) {
            console.error("Error loading products and product details URL:", error);
        }
    }

    private async loadProducts(products: ProductInterface[]): Promise<void> {
        const productHtmlArray: string[] = [];

        for (const product of products) {
            const productHtml: string = await this.createProductHtml(product);
            productHtmlArray.push(productHtml);
            this.totalProducts++;
        }

        if (this.listContainer) {
            this.listContainer.innerHTML = '';
            productHtmlArray.forEach(productHtml => {
                this.listContainer.insertAdjacentHTML('beforeend', productHtml);
            });
            this.initAddToCartForAll();
        }
    }

    async fetchProductData(): Promise<ProductInterface[]> {
        try {
            const response = await fetch(`/api/products/filter`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();

            if (data !== undefined) {
                return data;
            }

            return [];
        } catch (error) {
            console.error('Error fetching product data:', error);
            return [];
        }
    }

    async createProductHtml(product: ProductInterface): Promise<string> {
        const price: MoneyV2Interface = product.variants.edges[0].node.price;
        const variant: ProductVariantInterface = product.variants.edges[0].node;
        const prodId: string = product.id.split('/').pop() || '';
        const varPrice: number = price.amount || 0;
        const roundPrice: string = `${varPrice.toFixed(2)}`;
        const priceSplit: string[] = roundPrice.split(".");
        const productTags: string = product.tags?.join(',') || '';
        let varIdSplit = variant.id.split('/');
        let varId = varIdSplit[varIdSplit.length - 1];
        let varTypeSplit = variant.id.split('/');
        let varType = varTypeSplit[varTypeSplit.length - 2];
        const productDetailsUrl: string = `${this.productDetailsUrl}?id=${prodId}`;

        const disabled: string = !product.availableForSale ? "disabled" : "";

        const productHtml: string = `
    <div id="product-${prodId}" class="product-container list-container" data-tags="${productTags}" data-title="${product.title}" data-price="${varPrice}" data-var-id="${varId}" data-var-type="${varType}">
        <div class="product ${this.backgroundLabel}-bg" data-product-id="product-${prodId}">
            ${product.featuredImage ? `<div class="product-featured-image"><img src="${product.featuredImage.url}" alt="${product.featuredImage.altText}" /></div>` : ''}
            <div class="product-details">
                <div class="product-title ${this.backgroundLabel}-heading">${product.title}</div>
                <div class="product-details-more">
                    <div class="product-price ${this.backgroundLabel}-text">
                        <p class="price-sm">$</p>
                        <p class="price-lg">${priceSplit[0]}</p>
                        <p class="price-sm">.${priceSplit[1]}</p>
                    </div>
                    <div class="product-view">
                        <a href="${productDetailsUrl}" class="btn base-btn-bg base-btn-bg-solid base-btn-bg-hover-solid base-btn-text base-btn-borders">
                            <span></span>
                            <div class="text">View</div>
                            <i class="icon ki-forward-arrow before"></i>
                        </a>
                        <div class="add-to-cart ${disabled}">
                            <button class="button btn base-btn-bg base-btn-bg-solid base-btn-bg-hover-solid base-btn-text base-btn-borders btn--solid btn--large add">
                                <span></span>
                                <div class="text">Add</div>
                                <i class="ki-cart-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="background-decoration" src="/media/cqijjzyz/bottom-decor-transparent-white-1x.png" />
    </div>`;

        return productHtml;
    }

    private initAddToCartForAll(): void {
        const products = this.listContainer.querySelectorAll<HTMLElement>('.product-container');
        products.forEach(container => {
            this.initAddToCart(container);
        });
    }

    private initAddToCart(container: HTMLElement): void {

        const varId = container.getAttribute('data-var-id');
        const varType = container.getAttribute('data-var-type');
        // Shopify product variant details
        let fullId: string = "gid://shopify/" + varType + "/" + varId;

        // Add to cart
        let wrapper: HTMLElement = container.querySelector(".add-to-cart");
        let disabled: boolean = wrapper.classList.contains("disabled");
        let addBtn: HTMLButtonElement = container.querySelector(".button.add");

        if (!disabled) {

            // Add item to cart
            addBtn.addEventListener("click", () => {
                this.showBtnLoading(addBtn);
                const line: CartLineUpdateInputInterface = {
                    merchandiseId: fullId,
                    quantity: 1
                }

                let lines: CartLineUpdateInputInterface[] = [line];
                AddToShopifyCart(lines);
                ShopEvent.on(cartAfterUpdate, () => {
                    this.hideBtnLoading(addBtn);
                    ShopEvent.emit(cartShow);
                });
            });
        };
    };

    private showBtnLoading(btn: HTMLButtonElement): void {
        const loadingSpan: HTMLSpanElement = btn.querySelector(".sm");

        if (loadingSpan == undefined) {
            const newLoading: HTMLSpanElement = document.createElement("span");
            newLoading.classList.add("sm");
            newLoading.classList.add("sm-loading");
            newLoading.classList.add("sm-is-spinning");
            btn.appendChild(newLoading)
        }
    };

    private hideBtnLoading(btn: HTMLButtonElement): void {
        const loadingSpan: HTMLSpanElement = btn.querySelector(".sm");

        if (loadingSpan != undefined) {
            btn.removeChild(loadingSpan);
        }
    };

    async getProductDetailsPageUrl(): Promise<string> {
        try {
            const response = await fetch(`/api/products/get/Url`);
            if (!response.ok) {
                throw new Error(`Error fetching product details page URL: ${response.statusText}`);
            }
            const productPageDetailsUrl = await response.text();
            return productPageDetailsUrl;
        } catch (error) {
            console.error("Error getting product details page URL:", error);
            return '';
        }
    }

    private showPage(pageNumber: number): void {
        if (this.listContainer) {
            const productContainers = this.listContainer.querySelectorAll<HTMLDivElement>('.list-container');
            const startIndex = (pageNumber - 1) * this.productsPerPage;
            const endIndex = Math.min(startIndex + this.productsPerPage, productContainers.length);

            productContainers.forEach((container, index) => {
                container.style.display = index >= startIndex && index < endIndex ? 'flex' : 'none';
            });

            const offset = -300; 
            const elementPosition = this.listContainer.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    private createPaginationControls(): void {
        if (this.listContainer) {
            let paginationContainer = this.listContainer.parentElement.querySelector<HTMLElement>('.pagination');

            if (paginationContainer) {
                paginationContainer.remove();
            }

            paginationContainer = document.createElement('div');
            paginationContainer.classList.add('pagination');

            const prevButton = document.createElement('button');
            prevButton.textContent = 'Previous';
            prevButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
            prevButton.addEventListener('click', () => {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                }
            });
            paginationContainer.appendChild(prevButton);

            const nButtons = document.createElement('div');
            for (let i = 1; i <= this.totalPages; i++) {
                const pageButton = document.createElement('button');
                pageButton.textContent = String(i);
                pageButton.addEventListener('click', () => {
                    this.currentPage = i;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                });
                nButtons.appendChild(pageButton);
            }
            paginationContainer.appendChild(nButtons);

            const nextButton = document.createElement('button');
            nextButton.textContent = 'Next';
            nextButton.classList.add('btn', 'base-btn-bg', 'base-btn-bg-solid', 'base-btn-bg-hover-solid', 'base-btn-text', 'base-btn-borders');
            nextButton.addEventListener('click', () => {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.showPage(this.currentPage);
                    this.updatePaginationButtons();
                }
            });
            paginationContainer.appendChild(nextButton);

            this.listContainer.parentElement.appendChild(paginationContainer);
            this.updatePaginationButtons();
        }
    }

    private updatePaginationButtons(): void {
        if (this.listContainer) {
            const paginationButtons = this.listContainer.parentElement.querySelectorAll<HTMLButtonElement>('.pagination button');
            paginationButtons.forEach(button => {
                button.classList.add('c1-text');
                const pageNumber = parseInt(button.textContent!);
                if (pageNumber === this.currentPage) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }
            });
        }
    }
}
