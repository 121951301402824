import { getCookie, setCookie } from "typescript-cookie";
import { CartInputInterface } from "../interfaces/Shopify/CartInputInterface";
import { CartInterface } from "../interfaces/Shopify/CartInterface";
import { CartLineUpdateInputInterface } from "../interfaces/Shopify/CartLineUpdateInputInterface";
import { AsyncRequest } from "./AsyncRequest";
import { cartUpdate, ShopEvent } from "./Shop-Events";
import { isEmptyOrUndefined } from "./Util";

export function CreateShopifyCart(cartInput: CartInputInterface) {
    let data: FormData = new FormData();
    data.append("cartInput", JSON.stringify(cartInput));

    AsyncRequest<CartInterface>("/api/shop/cart/create", {
        method: "post",
        body: data
    }).then((response: CartInterface) => {
        ShopEvent.emit(cartUpdate, response);
        setCookie("cart", response.id, { expires: 7, path: '' })
        setCookie("carturl", response.checkoutUrl, { expires: 7, path: '' })
    });
};

export function GetShopifyCart<TResponse>(cartId: string): Promise<TResponse> {
    // Either pass the cart ID directly or get the cart id from the cart cookie.
    if (!isEmptyOrUndefined(cartId)) {
        cartId = getCookie("cart");
    }
    return fetch("/api/shop/cart?id=" + cartId)
        .then(response => response.json())
        .then(data => data)
};

export function AddToShopifyCart(cartLines: CartLineUpdateInputInterface[]) {
    let cartId: string = getCookie("cart");

    if (isEmptyOrUndefined(cartId)) {
        // We don't have a cart yet, so go create a new one with the product data we have.
        const newCart: CartInputInterface = {
            lines: cartLines,
            buyerIdentity: "",
            note: ""
        };
        CreateShopifyCart(newCart);

    } else {
        let data: FormData = new FormData();
        data.append("cartId", cartId);
        data.append("cartLines", JSON.stringify(cartLines));

        AsyncRequest<CartInterface>("/api/shop/cart/add", {
            method: "post",
            body: data
        }).then((response: CartInterface) => {
            ShopEvent.emit(cartUpdate, response);
        })
    }
};

export function UpdateShopifyCart(cartLines: CartLineUpdateInputInterface[]) {
    let cartId: string = getCookie("cart");

    if (isEmptyOrUndefined(cartId)) {
        // We don't have a cart yet, so go create a new one with the product data we have.
        const newCart: CartInputInterface = {
            lines: cartLines,
            buyerIdentity: "",
            note: ""
        };
        CreateShopifyCart(newCart);
    }
    else {
        let data: FormData = new FormData();
        data.append("cartId", cartId);
        data.append("cartLines", JSON.stringify(cartLines));

        AsyncRequest<CartInterface>("/api/shop/cart/update", {
            method: "post",
            body: data
        }).then((response: CartInterface) => {
            ShopEvent.emit(cartUpdate, response);
        });
    }
};

export function RemoveFromShopifyCart(cartLineId: string) {
    let cartId: string = getCookie("cart");

    if (!isEmptyOrUndefined(cartId) && !isEmptyOrUndefined(cartLineId)) {
        let data: FormData = new FormData();
        let ids: string[] = [cartLineId]
        data.append("cartId", cartId);
        data.append("lineIds", JSON.stringify(ids));

        AsyncRequest<CartInterface>("/api/shop/cart/removeline", {
            method: "post",
            body: data
        }).then((response: CartInterface) => {
            ShopEvent.emit(cartUpdate, response);
        });
    }
};