import CreateProducts from '../../ts/components/create-products';

export default class ProductTagFilter {
    private listContainer: HTMLElement | null;
    private productsComponent: HTMLElement | null;
    private backgroundLabel: string | null;
    private categoryMenu: string | null;
    private selectedValue: string | null;
    private sortValue: string | null;

    constructor(sortFilterOption: string, element: HTMLElement, categoryMenu: string, componentId: string, listingId: string, backgroundLabel: string, tags: string) {
        this.categoryMenu = categoryMenu;
        this.backgroundLabel = backgroundLabel;
        this.listContainer = document.getElementById(listingId);
        this.productsComponent = document.getElementById(componentId);
        this.sortValue = this.listContainer.querySelector(".sort-selected").innerHTML;

        if (element) {
            this.selectedValue = element.getAttribute("data-value")!;
            document.getElementById(sortFilterOption)!.innerText = this.selectedValue;

            const dropdownItems: NodeListOf<HTMLElement> = this.listContainer.querySelectorAll(".dropdown-item");
            dropdownItems.forEach(item => {
                item.querySelector("a")!.setAttribute("aria-selected", "false");
            });

            document.getElementById(sortFilterOption)!.innerText = this.selectedValue;
            element.querySelector("a")!.setAttribute("aria-selected", "true");
        } else {
            this.selectedValue = tags;
            if (tags == null) {
                this.selectedValue = this.listContainer.querySelector('.tags-selected').innerHTML
            }
        }

        const searchField = document.getElementById("searchProductField") as HTMLInputElement;
        const searchValue = searchField ? searchField.value.trim() : '';

        this.fetchProductData(this.selectedValue, searchValue);
    }

    async fetchProductData(selectedValue: string, searchValue: string): Promise<void> {
        if (this.categoryMenu != null) {
            document.getElementById(this.categoryMenu)!.classList.remove("show");
        }
        const source: string = `/api/products/filter?tag=${selectedValue}&sort=${this.sortValue}&search=${encodeURIComponent(searchValue)}`;
        try {
            const response = await fetch(source, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const data = await response.json();
            if (data !== undefined) {
                new CreateProducts(data, this.productsComponent.id, this.backgroundLabel, 100, "False", "True");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }
}
