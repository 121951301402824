//Components
import { Store } from "./components/store"
import Weather from "./components/weather"
import GoogleMap from "./components/google-map"
import AnchorNavigation from "./components/anchor-navigation"
import Video from "./components/video"
import Product from "./Components/product";
import ProductList from "./Components/product-list";
import Cart from "./Components/cart";
import ViewFilter from "./Components/view-filter";
import ShowFilter from "./Components/show-filter";
import PriceFilter from "./Components/price-filter";
import ProductTagFilter from "./components/product-tag-filter";
import SortProductFilter from "./Components/sort-product-filter";
import SortEventFilter from "./components/sort-event-filter";
import EventTagFilter from "./components/event-tag-filter";
import SearchEvents from "./Components/search-events";
import CreateEvents from "./Components/create-events";
import CreateProducts from "./Components/create-products";
import Tags from "./Components/tags";
import Instagram from "./components/instagram";
import CreateRelatedEvents from "./components/create-related-events";

class CoreSite {
    constructor() {

    }
}

export const Core: CoreSite = new CoreSite();
export { Store };
export { Weather };
export { GoogleMap };
export { AnchorNavigation };
export { Video };
export { Instagram };
export { Product };
export { ProductList };
export { Cart };
export { ViewFilter };
export { ShowFilter };
export { PriceFilter };
export { ProductTagFilter };
export { SortProductFilter };
export { SortEventFilter };
export { EventTagFilter };
export { SearchEvents };
export { CreateEvents };
export { CreateProducts };
export { Tags };
export { CreateRelatedEvents };